import Form from "react-bootstrap/esm/Form";

function CategoryDropdown() {
  return (
    <Form.Select id="category_dropdown" aria-label="Choose content category">
      <option>Choose content category</option>
      <option value="motivation">motivation</option>
      <option value="fitness">fitness</option>
      <option value="forex">forex</option>
      <option value="relationship">relationship</option>
      <option value="generic">generic</option>
      <option value="generic">interview prep</option>
    </Form.Select>
  );
}

export default CategoryDropdown;
