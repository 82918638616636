import { Stack, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { auth, useAuth } from '../auth/firebase-auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useRef, useState } from 'react';
import { Home } from './home';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

/* Sign up page component */
export const SignUp = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  function createUser() {
    if (inputEmailRef.current && inputPasswordRef.current) {
      const email = inputEmailRef.current.value.trim();
      const password = inputPasswordRef.current.value.trim();

      if (!email.length || !password.length) {
        handleShow();
        return;
      }
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          console.log(user);
          navigate('/dashboard');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      {currentUser ? (
        <Home />
      ) : (
        <Stack gap={3}>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please enter valid email and password</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <br></br>
          <h4>Create your account</h4>

          <Form.Label htmlFor="inputEmail">Email</Form.Label>
          <Form.Control
            type="email"
            id="inputEmail"
            aria-describedby="passwordHelpBlock"
            ref={inputEmailRef}
          />
          <Form.Label htmlFor="inputPassword">Password</Form.Label>
          <Form.Control
            type="password"
            id="inputPassword"
            aria-describedby="passwordHelpBlock"
            ref={inputPasswordRef}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Your password must be 8-20 characters long, contain letters and
            numbers, and must not contain spaces, special characters, or emoji.
          </Form.Text>
          <Button onClick={() => createUser()} variant="dark">
            Create my account
          </Button>
        </Stack>
      )}
    </>
  );
};
