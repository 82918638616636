import { Button } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { query, where, collection, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db, useAuth } from '../../auth/firebase-auth';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import {
  defaultLayoutIcons,
  DefaultVideoLayout
} from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useRef } from 'react';
import { doc as firebaseDoc } from 'firebase/firestore';
import { updateDoc } from '@firebase/firestore';
import Modal from 'react-bootstrap/Modal';

function NextVideo(props: { isAccountConnected: boolean }) {
  const { currentUser } = useAuth();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoCaption, setVideoCaption] = useState('');
  const [commentDisabled, setcommentDisabled] = useState(false);
  const [stitchDisabled, setstitchDisabled] = useState(false);
  const [duetsDisabled, setduetsDisabled] = useState(false);
  const [enableYourBrand, setEnableYourBrand] = useState(false);
  const [enableBrandedContent, setEnableBrandedContent] = useState(false);
  const [brandedContentDisabled, setBrandedContentDisabled] = useState(false);
  const [contentDisclosureText, setContentDisclosureText] = useState('');
  const [hideDiscloseVideoContent, setHideDiscloseVideoContent] =
    useState(true);
  const videoCaptionRef = useRef<HTMLTextAreaElement>(null);
  const [isVideosAvailable, setisVideosAvailable] = useState<boolean>(false);
  const { contentData, loading, error } = useFirestoreQuery(
    currentUser.email,
    setisVideosAvailable
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleVideoCaptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setVideoCaption(event.target.value);
  };

  const handleUpdate = () => {
    return async () => {
      if (videoCaptionRef.current) {
        handleShow();
        const videoCaptionRefValue = videoCaptionRef.current.value;
        const videoPrivacy = (
          document.getElementById('video_privacy') as HTMLSelectElement
        ).value;

        const collectionName = 'user_content';
        const q = query(
          collection(db, collectionName),
          where('user_email', '==', currentUser.email)
        );
        const accountQuerySnapshot = await getDocs(q);

        accountQuerySnapshot.forEach(async (doc) => {
          try {
            const docRef = firebaseDoc(db, collectionName, doc.id);
            const nextContent = {
              video_caption: videoCaptionRefValue,
              comments_disabled: commentDisabled,
              stitches_disabled: stitchDisabled,
              duets_disabled: duetsDisabled,
              content_disclosure_your_brand: enableYourBrand,
              content_disclosure_branded_content: enableBrandedContent,
              video_privacy: videoPrivacy
            };

            await updateDoc(docRef, nextContent);
          } catch (error) {
            console.error('Error during callback:', error);
          }
        });
      }
    };
  };

  useEffect(() => {
    if (currentUser && contentData.length > 0 && !error && !loading) {
      setVideoUrl(contentData[0].video_url);
      setVideoCaption(contentData[0].video_caption);
      setcommentDisabled(contentData[0].comments_disabled);
      setstitchDisabled(contentData[0].stitches_disabled);
      setduetsDisabled(contentData[0].duets_disabled);
    }
  }, [currentUser, contentData, error, loading]);

  const stateSetterMap: {
    [key: string]: React.Dispatch<React.SetStateAction<boolean>>;
  } = {
    commentDisabled: setcommentDisabled,
    stitchDisabled: setstitchDisabled,
    duetsDisabled: setduetsDisabled,
    discloseVideoContent: setHideDiscloseVideoContent,
    brandContent: setEnableBrandedContent,
    yourBrand: setEnableYourBrand
  };

  const toggleState = (id: string) => {
    const setState = stateSetterMap[id];
    setState((prevState) => !prevState);
    if (id === 'yourBrand' && !enableYourBrand) {
      if (enableBrandedContent) {
        setContentDisclosureText('Paid partnership');
      } else {
        setContentDisclosureText('Promotional content');
        (document.getElementById('video_privacy') as HTMLSelectElement).value =
          'FOLLOWER_OF_CREATOR';
      }
    }

    if (id === 'brandContent' && !enableBrandedContent) {
      if (enableYourBrand) {
        setContentDisclosureText('Paid partnership');
      } else {
        setContentDisclosureText('Promotional content');
      }
    }

    if (id === 'yourBrand' && enableYourBrand && enableBrandedContent) {
      setContentDisclosureText('Paid partnership');
    }
  };

  const onSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleState(event.target.id);
  };

  const handleVideoPrivacyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target.value === 'SELF_ONLY') {
      setBrandedContentDisabled(true);
      setEnableYourBrand(false);
      setEnableBrandedContent(false);
    } else {
      setBrandedContentDisabled(false);
    }
  };

  return (
    <Stack gap={1}>
      <h2 style={{ marginTop: '10px' }}>Your content</h2>
      <Form.Label>
        Next post is scheduled for {getNextDay()} at 10:00 AM
      </Form.Label>
      {!isVideosAvailable && (
        <>
          <Alert key="primary" variant="primary">
            Your next video has not yet been created. It will appear here once
            it is ready.
          </Alert>
        </>
      )}

      {isVideosAvailable && (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>Video content updated</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Alert key="primary" variant="primary">
            Here's how your next video would look like. You can make changes
            before it is posted to your account
          </Alert>
          <MediaPlayer
            title="Your next video"
            src={videoUrl}
            aspectRatio="16/9"
          >
            <MediaProvider />
            <DefaultVideoLayout icons={defaultLayoutIcons} />
          </MediaPlayer>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>TikTok video caption</Form.Label>
              <Form.Control
                id="inputVideoCaption"
                as="textarea"
                rows={5}
                value={videoCaption}
                ref={videoCaptionRef}
                onChange={handleVideoCaptionChange}
              />

              <Form.Label style={{ marginTop: '10px' }}>
                Who can view this video
              </Form.Label>
              <Form.Select
                id="video_privacy"
                aria-label=" Who can view this video"
                onChange={handleVideoPrivacyChange}
              >
                <option value="PUBLIC_TO_EVERYONE">public</option>
                <option value="MUTUAL_FOLLOW_FRIENDS">friends</option>
                <option disabled={!hideDiscloseVideoContent} value="SELF_ONLY">
                  private
                </option>
              </Form.Select>
              
            </Form.Group>
            <Form.Label>Video settings</Form.Label>
            <Form.Check
              type="switch"
              id="commentDisabled"
              label="Disable comments"
              checked={commentDisabled}
              onChange={onSettingsChange}
            />

            <Form.Check
              type="switch"
              id="stitchDisabled"
              label="Disable stitches"
              checked={stitchDisabled}
              onChange={onSettingsChange}
            />

            <Form.Check
              type="switch"
              id="duetsDisabled"
              label="Disable duets"
              checked={duetsDisabled}
              onChange={onSettingsChange}
            />
          </Form>
          <hr />
          <Form.Label>Disclose video content</Form.Label>
          <Form.Label>
            Turn on to disclose that this video promotes goods or services in
            exchange for something of value. Your video could promote yourself,
            a third party, or both.
          </Form.Label>
          <Form.Check
            type="switch"
            id="discloseVideoContent"
            checked={!hideDiscloseVideoContent}
            onChange={onSettingsChange}
          />

          <Card hidden={hideDiscloseVideoContent}>
            <Card.Header as="h6">
              {' '}
              You need to indicate if your content promotes yourself, a third
              party, or both.
            </Card.Header>
            <Card.Body>
              <Alert
                key="primary"
                variant="primary"
                hidden={!brandedContentDisabled}
              >
                Branded content visibility cannot be set with private videos.
                Change your video visibility settings to either public or
                friends.
              </Alert>
              <Card.Text hidden={!enableYourBrand && !enableBrandedContent}>
                <Alert key="primary" variant="primary">
                  Your video will be labeled {contentDisclosureText}. This
                  cannot be changed once your video is posted.
                </Alert>
              </Card.Text>

              <Card.Text>
                <Form.Check
                  disabled={brandedContentDisabled}
                  type="switch"
                  id="yourBrand"
                  label="Your brand"
                  checked={enableYourBrand}
                  onChange={onSettingsChange}
                />
                <Form.Label>
                  You are promoting yourself or your own business. This video
                  will be classified as Brand Organic.
                </Form.Label>

                <Form.Check
                  disabled={brandedContentDisabled}
                  type="switch"
                  id="brandContent"
                  label="Brand content"
                  checked={enableBrandedContent}
                  onChange={onSettingsChange}
                />
                <Form.Label>
                  You are promoting another brand or a third party. This video
                  will be classified as Branded Content.
                </Form.Label>
                <hr />
                <Form.Label>
                  By posting your brand, you agree to TikTok's{' '}
                  <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">
                    Music Usage Confirmation
                  </a>
                  . By posting branded content you agree to{' '}
                  <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">
                    Music Usage Confirmation
                  </a>{' '}
                  and{' '}
                  <a href="https://www.tiktok.com/legal/page/global/bc-policy/en">
                    Branded content policy
                  </a>
                </Form.Label>
              </Card.Text>
            </Card.Body>
          </Card>
          <Button
            disabled={
              !hideDiscloseVideoContent &&
              !enableYourBrand &&
              !enableBrandedContent
            }
            variant="primary"
            onClick={handleUpdate()}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          >
            Update content
          </Button>
        </>
      )}
    </Stack>
  );
}

interface ContentStructure {
  video_url: string;
  video_caption: string;
  comments_disabled: boolean;
  stitches_disabled: boolean;
  duets_disabled: boolean;
  user_email: string;
  tiktok_account_category: string;
}

function useFirestoreQuery(
  email: string,
  setisVideosAvailable: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [contentData, setContentData] = useState<ContentStructure[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'user_content'),
          where('user_email', '==', email)
        );
        const querySnapshot = await getDocs(q);
        const newData: ContentStructure[] = [];
        querySnapshot.forEach((doc) => {
          setisVideosAvailable(true);
          newData.push(doc.data() as ContentStructure);
        });
        setContentData(newData);
      } catch (error) {
        //setError(error as unknown);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Clean-up function
    return () => {};
  }, [email, setisVideosAvailable]);

  return { contentData, loading, error };
}

function getNextDay() {
  const currentDate = new Date();
  const nextDayTimestamp = currentDate.getTime() + 24 * 60 * 60 * 1000;
  const nextDay = new Date(nextDayTimestamp);
  const dateFormatter = new Intl.DateTimeFormat('en-GB');
  const formattedDate = dateFormatter.format(nextDay);
  return formattedDate;
}

export default NextVideo;
