import { Badge, Col, Container, Row } from 'react-bootstrap';
import { db, firebaseFunctions, useAuth } from '../auth/firebase-auth';
import { httpsCallable } from 'firebase/functions';
import { query, where } from 'firebase/firestore';
import { Login } from './login';
import ConnectedAccounts from '../components/connected-accounts/connected-accounts';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import NextVideo from '../components/next-video/next-video';

const getUserProfileOnCall = httpsCallable<{}, { user_data: UserData }>(
  firebaseFunctions,
  'getUserProfileOnCall'
);

interface UserData {
  avatar_url: string;
  display_name: string;
  open_id: string;
  union_id: string;
}

interface AccessTokenResponse {
  expires_in: number;
  refresh_expires_in: number;
  token_type: string;
  open_id: string;
  scope: string;
  refresh_token: string;
  access_token: string;
}

interface UserDataDetails {
  stitch_disabled: boolean;
  creator_username: string;
  comment_disabled: boolean;
  creator_avatar_url: string;
  duet_disabled: boolean;
  privacy_level_options: string[];
  creator_nickname: string;
  max_video_post_duration_sec: number;
}

interface ConnectedAccount {
  tiktok: {
    connected_social_media_account: string;
    account_data: {
      access_token_data: AccessTokenResponse;
      user_data: UserDataDetails;
    };
  };
}

interface TikTokData {
  connected_accounts: ConnectedAccount;
  account_status: string;
  user_uid: string;
  tiktok_account_category: string;
  email: string;
}

function useFirestoreQuery(email: string) {
  const [profileData, setProfileData] = useState<TikTokData[]>([]); // Use the TikTokUser interface as the type for newData
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'tiktok_automation_users'),
          where('email', '==', email)
        );
        const querySnapshot = await getDocs(q);
        const newData: TikTokData[] = []; // Define newData with the TikTokUser interface
        querySnapshot.forEach((doc) => {
          newData.push(doc.data() as TikTokData); // Cast doc.data() to TikTokUser
        });
        setProfileData(newData);
      } catch (error) {
        //setError(error as unknown);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Clean-up function
    return () => {};
  }, [email]); // Include currentUser in the dependencies array if you need to re-fetch data when the user changes

  return { profileData, loading, error };
}

/* Home page component */
export const Home = () => {
  const { currentUser } = useAuth();
  const [profileName, setProfileName] = useState('');
  const [accountCategoryVal, setAccountCategoryVal] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isTikTokAccountConnected, setisTikTokAccountConnected] =
    useState(false);

  const { profileData, loading, error } = useFirestoreQuery(currentUser.email);

  useEffect(() => {
    if (!currentUser || profileData.length === 0 || error || loading) return;

    const { tiktok_account_category, connected_accounts } = profileData[0];
    const { access_token_data, user_data } =
      connected_accounts.tiktok.account_data;

    const account_category = tiktok_account_category;
    setAccountCategoryVal(account_category);

    const { access_token } = access_token_data;
    if (access_token) {
      setisTikTokAccountConnected(true);
      const data = { code: access_token };
      getUserProfileOnCall(data)
        .then((result) => {
          const { creator_nickname, creator_avatar_url } = user_data;
          setProfileName(creator_nickname);
          setAvatarUrl(creator_avatar_url);
        })
        .catch(console.error);
    }
  }, [currentUser, profileData, error, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <Row>
            <Col>
              <div className="p-2">
                <h2>Dashboard</h2>
                <p>Social media accounts</p>
                <ConnectedAccounts
                  name={profileName}
                  avatarUrl={avatarUrl}
                  isAccountConnected={isTikTokAccountConnected}
                  accountCategory={accountCategoryVal}
                  email={currentUser.email}
                />
              </div>
            </Col>
            <Col>
              <NextVideo isAccountConnected={isTikTokAccountConnected} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Login />
      )}
    </>
  );
};
