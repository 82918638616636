import React from 'react';
import './App.css';
import NavBar from './components/navigation/navigation-bar';
import Container from 'react-bootstrap/esm/Container';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Redirect } from './pages/redirect';
import { Login } from './pages/login';
import { SignUp } from './pages/signup';
import { AddAccount } from './pages/addAcc1';
import { PrivacyPolicy } from './pages/privacy-policy';
import { AboutUs } from './pages/about-us';
import { TermsOfService } from './pages/terms-of-service';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Container>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<Login />}></Route>
            <Route path="/auth-complete" element={<Redirect />}></Route>
            <Route path="/dashboard" element={<Home />}></Route>
            <Route path="/create-account" element={<SignUp />}></Route>
            <Route path="/add-acc1" element={<AddAccount />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route
              path="/terms-of-service"
              element={<TermsOfService />}
            ></Route>
          </Routes>
        </Container>
      </BrowserRouter>
    </>
  );
};
export default App;
