import { Stack } from 'react-bootstrap';

/*Privacy policy page component */
export const PrivacyPolicy = () => {
  return (
    <Stack gap={3}>
      <br></br>
      <h3>Privacy policy</h3>
      <p>
        Your privacy is important to us. This Privacy Policy explains how we
        collect, use, and disclose information through our website, Content
        Publisher - FunFlickHub. By accessing or using our platform, you agree
        to the terms of this Privacy Policy.
      </p>

      <h4>1. Information We Collect</h4>
      <p>
        We collect information that you provide to us when you register for an
        account, fill out forms, or interact with our platform in any way. This
        may include your name, email address, and any other information you
        choose to provide.
      </p>

      <h4>2. How We Use Your Information</h4>
      <p>
        We use the information we collect to provide and improve our services,
        communicate with you, and personalize your experience on our platform.
        We may also use your information for marketing purposes, with your
        consent.
      </p>

      <h4>3. Information Sharing and Disclosure</h4>
      <p>
        We may share your information with third-party service providers who
        assist us in operating our platform and delivering our services. We may
        also share your information in response to legal requests or to protect
        our rights and interests.
      </p>

      <h4>4. Cookies and Tracking Technologies</h4>
      <p>
        We use cookies and similar tracking technologies to analyze trends,
        administer our website, track users' movements around the site, and
        gather demographic information about our user base. You can control
        cookies through your browser settings.
      </p>

      <h4>5. Data Security</h4>
      <p>
        We take reasonable measures to protect the security of your information
        and prevent unauthorized access, disclosure, or alteration. However, no
        method of transmission over the internet or electronic storage is
        completely secure, so we cannot guarantee absolute security.
      </p>

      <h4>6. Your Choices and Rights</h4>
      <p>
        You have the right to access, correct, or delete your personal
        information. You may also opt out of receiving marketing communications
        from us at any time. Please contact us if you have any questions or
        concerns about your privacy rights.
      </p>

      <h4>7. Changes to This Policy</h4>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. We will notify you of any
        material changes by posting the updated policy on our website. Your
        continued use of Content Publisher - FunFlickHub after the changes
        indicates your acceptance of the revised policy.
      </p>
    </Stack>
  );
};
