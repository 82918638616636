import { useState } from 'react';
import { Card, Button, Badge } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/Modal';
import {
  doc as firebaseDoc,
  collection,
  getDocs,
  deleteField
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { query, where } from 'firebase/firestore';
import { updateDoc } from '@firebase/firestore';
import { db, firebaseFunctions } from '../../auth/firebase-auth';

function ConnectedAccounts(props: {
  name: string;
  avatarUrl: string;
  isAccountConnected: boolean;
  accountCategory: string;
  email: string;
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleUpdate = (email: string) => {
    return async () => {
      updateTikTokAccountCategoryByEmail(email).then((result) => {
        setShow(false);
        console.log(result);
      });
    };
  };

  return (
    <>
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={props.avatarUrl} />
        <Card.Body>
          <p>
            Posting schedule{' '}
            <Badge pill bg="success">
              2 posts per day
            </Badge>
          </p>
          <Card.Title>
            TikTok{' '}
            {props.isAccountConnected && <Card.Text>- {props.name}</Card.Text>}
          </Card.Title>
          <Card.Text>Post content automatically to TikTok.</Card.Text>
          <hr />
          {props.isAccountConnected ? (
            <>
              <Card.Text>
                Current account category - <b>{props.accountCategory}</b>{' '}
                content
              </Card.Text>
              <Form.Select
                id="category_dropdown"
                aria-label="Choose content category"
              >
                <option>Choose content category</option>
                <option value="motivation">motivation</option>
                <option value="fitness">fitness</option>
                <option value="forex">forex</option>
                <option value="travel">travel</option>
                <option value="relationship">relationship</option>
                <option value="generic">generic</option>
                <option value="interview_prep">interview prep</option>
              </Form.Select>
              <br />
              <Button variant="primary" onClick={handleShow}>
                {' '}
                Update account category
              </Button>
              <hr />
              <Button variant="danger" onClick={disconnectAccount(props.email)}>
                {' '}
                Disconnect TikTok account
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={request_token}>
              {' '}
              Connect
            </Button>
          )}
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to update account category ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate(props.email)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const getOauthURL = httpsCallable<{}, { url: string }>(
  firebaseFunctions,
  'getOauthURL'
);

const disconnectAccount = (email: string) => {
  return async () => {
    //delete access token from firebase account and refresh page
    await deleteTikTokAccountCategoryByEmail(email);
  };
};

const request_token = async () => {
  getOauthURL()
    .then((result) => {
      window.location.href = result.data.url;
    })
    .catch((error) => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
    });
};

async function deleteTikTokAccountCategoryByEmail(email: string) {
  const collectionName = 'tiktok_automation_users';

  const q = query(collection(db, collectionName), where('email', '==', email));
  const accountQuerySnapshot = await getDocs(q);

  accountQuerySnapshot.forEach(async (doc) => {
    try {
      const docRef = firebaseDoc(db, collectionName, doc.id);
      await updateDoc(docRef, {
        'connected_accounts.tiktok': deleteField(),
        account_status: 'disabled'
      });
      window.location.reload();
    } catch (error) {
      console.error('Error during callback:', error);
    }
  });
}

async function updateTikTokAccountCategoryByEmail(email: string) {
  const selectionName = 'category_dropdown';
  const selectElement = document.getElementById(
    selectionName
  ) as HTMLSelectElement;
  const newCategory = selectElement.value;
  const collectionName = 'tiktok_automation_users';

  const q = query(collection(db, collectionName), where('email', '==', email));
  const accountQuerySnapshot = await getDocs(q);

  accountQuerySnapshot.forEach(async (doc) => {
    try {
      const docRef = firebaseDoc(db, collectionName, doc.id);
      await updateDoc(docRef, {
        tiktok_account_category: newCategory
      });
      return true;
    } catch (error) {
      console.error('Error during callback:', error);
      return false;
    }
  });
}

export default ConnectedAccounts;
