import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { signOutUser, useAuth } from "../../auth/firebase-auth";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';

function NavBar() {
  const { currentUser } = useAuth();
    const navigate = useNavigate();
  const expand = 'md'
  return (
    <Navbar  bg="primary" data-bs-theme="dark" key={expand} expand={expand}>
      <Container fluid>
        <Navbar.Brand href="/">Content Publisher</Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
         <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
               <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
                 <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
          {currentUser && (
            <>
            
              <Nav.Link>
                Signed in as: {currentUser.email}
              </Nav.Link>
              <Nav.Link>
              <a onClick={()=>{ navigate("/about-us")}}>How it works</a>
            </Nav.Link>
              <Nav.Link>
                <a onClick={() => {
                  signOutUser();
                  navigate("/")
                }}>Sign out</a>
              </Nav.Link>
            </>
          )}
          {!currentUser && (
            <>
              <Nav.Link>
              <a onClick={()=>{ navigate("/login")}}>Sign In</a>
            </Nav.Link>
            <Nav.Link>
              <a onClick={()=>{ navigate("/create-account")}}>Create an account</a>
            </Nav.Link>
            <Nav.Link>
              <a onClick={()=>{ navigate("/privacy-policy")}}>Privacy policy</a>
            </Nav.Link>
            <Nav.Link>
              <a onClick={()=>{ navigate("/about-us")}}>About us</a>
            </Nav.Link>
            <Nav.Link>
              <a onClick={()=>{ navigate("/terms-of-service")}}>Terms of service</a>
            </Nav.Link>
            </>
          )}
        </Nav>
                 </Offcanvas.Body>
            </Navbar.Offcanvas>
        
      </Container>
    </Navbar>
  );
}

export default NavBar;
