import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
 import { getFunctions } from 'firebase/functions';
 import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
 import React, { createContext, useContext, useEffect, useState } from "react";
 import * as ls from "local-storage";

const firebaseConfig = {
  apiKey: "AIzaSyCoULvqaL54fDweviOjGWWd2m-zE3fdfbE",
  authDomain: "instagramposter-cf65c.firebaseapp.com",
  projectId: "instagramposter-cf65c",
  storageBucket: "instagramposter-cf65c.appspot.com",
  messagingSenderId: "183431430566",
  appId: "1:183431430566:web:4e7cbac4c658f07dbb4f67",
  measurementId: "G-0TS84CMTWM",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const firebaseFunctions = getFunctions(app);
export const auth = getAuth(app);

export function signOutUser(){
signOut(auth)
  .then(() => {
    ls.clear();
    // Sign-out successful.
  })
  .catch((error) => {
    // An error happened.
  });
}
// Create AuthContext
const AuthContext = createContext();
// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
// AuthProvider component to provide authentication state to the entire app
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
   const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
       setCurrentUser(user);
       setLoading(false);
      // if (user) {
      //   // User is signed in, see docs for a list of available properties
      //   // https://firebase.google.com/docs/reference/js/auth.user
      //   const uid = user.uid;
      //   // ...
      // } else {
      //   // User is signed out
      //   // ...
      // }
    });

    // const unsubscribe = onAuthStateChanged(user => {
    //   setCurrentUser(user);
    // });
    // return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/auth.user
//     const uid = user.uid;
//     // ...
//   } else {
//     // User is signed out
//     // ...
//   }
// });
// export const firebaseAuth = firebase.auth();
