import { Stack } from "react-bootstrap";

/*Privacy policy page component */
export const AboutUs = () => {
  return (
         <Stack gap={3}>
          <br></br>
          <h3>About Us</h3>
        <p>At Content Publisher - FunFlickHub, we're dedicated to making your content publishing experience enjoyable and hassle-free. Our platform is your one-stop destination for planning, creating, and analyzing your posts, all while having fun along the way.</p>
        
        <h3>Our Mission</h3>
        <p>Our mission is to provide content creators and marketers with the ultimate toolset to unleash their creativity and maximize their impact in the digital world. We believe that managing content should be an exciting journey, not a daunting task. That's why we've created a platform that combines functionality with entertainment.</p>
        
        <h3>What We Offer</h3>
        <ul>
            <li><strong>Content Planning:</strong> Dive into our intuitive content calendar to plan your posts with ease. Whether you're scheduling movie reviews, behind-the-scenes content, or exciting announcements, we've got the tools to help you stay organized.</li>
            <li><strong>Content Creation:</strong> Let your imagination run wild with our user-friendly editor. From crafting captivating blog posts to designing eye-catching social media graphics, unleash your creativity and bring your ideas to life.</li>
            <li><strong>Content Analysis:</strong> Measure the success of your content campaigns with our powerful analytics dashboard. Gain insights into audience engagement, track performance metrics, and fine-tune your strategy for maximum impact.</li>
        </ul>
        
        <h3>Why Choose Us</h3>
        <ul>
            <li><strong>Fun and Engaging Experience:</strong> We believe that content creation should be fun! That's why we've infused our platform with a sense of excitement and enjoyment, making every step of the process a delightful experience.</li>
            <li><strong>Comprehensive Features:</strong> With a wide range of features tailored to meet your needs, Content Publisher - FunFlickHub offers everything you need to succeed in the competitive world of digital content.</li>
            <li><strong>Dedicated Support:</strong> Our friendly support team is here to assist you whenever you need help or have questions. We're committed to ensuring that you have a smooth and enjoyable experience with our platform.</li>
        </ul>
        
        <h3>Get Started Today</h3>
        <p>Ready to embark on your content publishing journey with Content Publisher - FunFlickHub? Sign up now and join the community of content creators who are redefining the way content is published and shared online. Let's make content creation fun again!</p>
        </Stack>
  );
};
