import { Stack } from "react-bootstrap";

/*Privacy policy page component */
export const TermsOfService = () => {
  return (
         <Stack gap={3}>
          <br></br>
          <h2>Terms of Use</h2>
        <p>Welcome to Content Publisher - FunFlickHub! These terms govern your use of our website and services, so please read them carefully. By accessing or using our platform, you agree to abide by these terms and conditions.</p>
        
        <h3>1. Acceptance of Terms</h3>
        <p>By accessing or using Content Publisher - FunFlickHub, you agree to comply with these terms and conditions. If you do not agree with any of these terms, you may not use our platform.</p>
        
        <h3>2. User Accounts</h3>
        <p>In order to access certain features of our platform, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.</p>
        
        <h3>3. Content Guidelines</h3>
        <p>Users are solely responsible for the content they publish on Content Publisher - FunFlickHub. You agree not to post any content that is unlawful, defamatory, or infringes on the rights of others.</p>
        
        <h3>4. Intellectual Property</h3>
        <p>All content and materials available on Content Publisher - FunFlickHub are protected by copyright and other intellectual property laws. You may not use, modify, or distribute any content from our platform without prior authorization.</p>
        
        <h3>5. Limitation of Liability</h3>
        <p>Content Publisher - FunFlickHub is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy or reliability of our platform. In no event shall we be liable for any damages arising from your use of our services.</p>
        
        <h3>6. Governing Law</h3>
        <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>
        
        <h3>7. Changes to Terms</h3>
        <p>We reserve the right to modify or update these terms of use at any time without prior notice. It is your responsibility to review these terms periodically for changes. Your continued use of Content Publisher - FunFlickHub after any modifications indicates your acceptance of the updated terms.</p>
        
        <p>If you have any questions or concerns about these terms, please contact us at [hello@funflickshub.com.ng].</p>
        </Stack>
  );
};
