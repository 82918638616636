import React from 'react';
import { useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { db, firebaseFunctions, useAuth } from '../auth/firebase-auth';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { Alert } from 'react-bootstrap';
import * as ls from 'local-storage';

interface Creator {
  creator_avatar_url: string;
  creator_nickname: string;
  creator_username: string;
  duet_disabled: boolean;
  max_video_post_duration_sec: number;
  privacy_level_options: string[];
  stitch_disabled: boolean;
  comment_disabled: boolean;
}

interface accessTokenResponse {
  access_token: {
    access_token: string;
    expires_in: number;
    open_id: string;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
}

export const Redirect = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get('code');

    const getTikTokAccessTokenOnCall = httpsCallable<
      {},
      { access_token: accessTokenResponse }
    >(firebaseFunctions, 'getTikTokAccessTokenOnCall');

    const getUserAccountDetailsOnCall = httpsCallable<
      {},
      { user_data: Creator }
    >(firebaseFunctions, 'getUserAccountDetailsOnCall');

    getTikTokAccessTokenOnCall({
      code: code
    })
      .then((accessTokenResult) => {
        console.log(accessTokenResult.data.access_token);
        ls.set<Object>('accessToken', accessTokenResult.data.access_token);
        const accessToken = accessTokenResult.data.access_token.access_token;
        getUserAccountDetailsOnCall({
          code: accessToken
        })
          .then((tiktokData) => {
            addDoc(collection(db, 'tiktok_automation_users'), {
              email: currentUser.email,
              user_uid: currentUser.uid,
              tiktok_account_category: 'generic',
              account_status: 'active',
              connected_accounts: {
                tiktok: {
                  account_data: {
                    access_token_data: accessTokenResult.data.access_token,
                    user_data: tiktokData.data.user_data
                  },
                  connected_social_media_account: 'TikTok'
                }
              }
            })
              .then((result) => {
                navigate('/dashboard');
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
      });
  }, [currentUser.email, currentUser.uid, navigate]);

  return (
    <Alert style={{ marginTop: '10px' }} key="primary" variant="success">
      Social media account added successfully. You will be directed to the
      dashboard in a moment...
    </Alert>
  );
};

export default Redirect;
