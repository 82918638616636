import { Stack, Button } from 'react-bootstrap';
import CategoryDropdown from '../components/category-dropdown/category-dropdown';
import * as ls from 'local-storage';
import { db, firebaseFunctions, useAuth } from '../auth/firebase-auth';
import { httpsCallable } from 'firebase/functions';
import { collection, addDoc } from 'firebase/firestore';
import { Login } from './login';

const getOauthURL = httpsCallable<{}, { url: string }>(
  firebaseFunctions,
  'getOauthURL'
);

/* Add account component */
export const AddAccount = () => {
  const { currentUser } = useAuth();
  if (!ls.get('accessToken') && currentUser) {
    request_token();
  }
  return (
    <>
      {currentUser ? (
        <Stack gap={3}>
          <div className="p-2">Select TikTok account category</div>
          <CategoryDropdown />
          <Button onClick={() => addTikTokAccount(currentUser)} variant="dark">
            Add TikTok account
          </Button>
        </Stack>
      ) : (
        <Login />
      )}
    </>
  );
};

async function addTikTokAccount(currentUser: any) {
  const selectionName = 'category_dropdown';
  const selectElement = document.getElementById(
    selectionName
  ) as HTMLSelectElement;
  const selectedValue = selectElement.value;
  ls.set<string>(selectionName, selectedValue);
  //Add firestore collection it with category, email, and access token
  //Print done afterwards

  try {
    const docRef = await addDoc(collection(db, 'tiktok_automation_users'), {
      email: currentUser.email,
      user_uid: currentUser.uid,
      tiktok_account_category: selectedValue.replace(/ /g, '_'),
      account_status: 'active',
      connected_accounts: {
        tiktok: {
          account_data: {
            access_token_data: ls.get('accessToken'),
            user_data: {}
          },
          connected_social_media_account: 'TikTok'
        }
      }
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

const request_token = async () => {
  getOauthURL()
    .then((result) => {
      window.location.href = result.data.url;
    })
    .catch((error) => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
    });
};
